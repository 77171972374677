
import { defineComponent, ref, reactive } from "vue";
import CloudFun from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "優惠活動",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.ActivityType).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "StartTime", title: "開始時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "EndTime", title: "結束時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        {
          field: "Published", title: "已發佈", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch("activity/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch('activity/query') : undefined,
        save: model
          ? (params) => model.dispatch('activity/save', params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      items: [
        { field: "Photo.Uri", span: 24, slots: { default: "column-photo" } },
        { field: "Number", title: "編號", span: 12, itemRender: { name: "$input", props: { placeholder: "未輸入則系統自動產生" } } },
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入文字" } } },
        { field: "Type", title: "類型", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.ActivityType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "StartTime", title: "開始時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入日期" } } },
        { field: "EndTime", title: "結束時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入日期" } } },
        { field: "Published", title: "已發佈", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
        { field: "Rules", title: "活動規則", span: 24, slots: { default: "column-rules" } },
        { field: "Description", title: "說明", span: 24, slots: { default: "column-description" } },
      ],
      rules: {
        Name: [{ required: true }],
        Type: [{ required: true }],
        StartTime: [{ required: true }],
      }
    };

    const ruleSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '請選擇規則',
      textField: 'Name',
      valueField: 'Id',
      multiselect: true,
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => model!.dispatch("marketingRule/find", value), // eslint-disable-line
        query: (params) => model!.dispatch("marketingRule/query", params) // eslint-disable-line
      },
    }

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      ruleSelectOptions,
      editorOptions,
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Type = 0;
        row.Published = false;
        row.Description = {};
        row.RuleIds = [];
      } else {
        const entity = await this.$model.dispatch("activity/find", row.Id);
        Object.assign(row, entity);
        row.RuleIds = row.Rules?.sort((a: any, b: any) => a.Priority - b.Priority).map((e: any) => e.MarketingRuleId) || [];
      }
      callback();
    },
    onFormSubmit(row: any, submit: any) {
      row.Rules = row.RuleIds.map((id: number) => ({ MarketingRuleId: id }));
      submit();
    }
  }
});
